import { createContext } from 'solid-js';
import { gql } from '../graphql';
import { cachedQuery } from '../graphql/cached-get';
import type { CardQuery, TroonCardSubscriptionProduct } from '../graphql';
import type { Accessor } from 'solid-js';

export const OldCardCtx = createContext<Accessor<CardQuery | undefined>>(() => undefined);

export const AccessProductsCtx = createContext<Accessor<Array<TroonCardSubscriptionProduct> | undefined>>(() => []);

const query = gql(`query subscriptionProducts($promoCode: String) {
  products: troonCardSubscriptionProducts(promoCode: $promoCode) {
    id
    active
    subscriptionName
    description
    type
    amount { ...Currency }
    discountAmount { ...Currency }
    subtotalAmount { ...Currency }
    totalAmount { ...Currency }
    valueProps
    additionalValueProps
    isPopular
    autoRenew
    disclosure
  }
}`);

export const getAccessProducts = cachedQuery(query);
